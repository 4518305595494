import React from 'react';
import { Helmet } from 'react-helmet-async';
import { 
  ChevronRight, 
  Mail, 
  MapPin, 
  Calendar, 
  Server, 
  Cpu, 
  Wifi, 
  Radio, 
  Wrench, 
  Factory, 
  UserCircle,
  Building 
} from 'lucide-react';

function App() {
  return (
    <div className="min-h-screen">
      <Helmet>
        <title>株式会社FREEL</title>
        <meta name="description" content="IoT端末開発とデジタルトランスフォーメーションを支援する企業です。" />
      </Helmet>
      {/* ヘッダー */}
      <header className="bg-white shadow-sm fixed w-full top-0 z-50">
        <nav className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between h-16 items-center">
            <div className="flex-shrink-0">
              <h1 className="text-xl font-bold text-gray-900">株式会社FREEL</h1>
            </div>
            <div className="hidden md:block">
              <div className="ml-10 flex items-center space-x-4">
                <a href="#about" className="text-gray-700 hover:text-gray-900 px-3 py-2 rounded-md">会社概要</a>
                <a href="#strengths" className="text-gray-700 hover:text-gray-900 px-3 py-2 rounded-md">強み</a>
                <a href="#services" className="text-gray-700 hover:text-gray-900 px-3 py-2 rounded-md">サービス</a>
                <a href="#contact" className="bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700">お問い合わせ</a>
              </div>
            </div>
          </div>
        </nav>
      </header>

      {/* ヒーローセクション */}
      <div className="relative bg-white pt-16">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-20 pb-16">
          <div className="text-center">
            <h2 className="text-4xl tracking-tight font-bold text-gray-900 sm:text-5xl md:text-6xl">
              <span className="block">IoTデバイスの開発と</span>
              <span className="block text-blue-600">デジタル化支援</span>
            </h2>
            <p className="mt-3 max-w-md mx-auto text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
              リモートで管理できるデバイスや通信インフラのサポートで、あなたのビジネスをもっと効率的にします。
            </p>
            <div className="mt-5 max-w-md mx-auto sm:flex sm:justify-center md:mt-8">
              <div className="rounded-md shadow">
                <a href="#contact" className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 md:py-4 md:text-lg md:px-10">
                  お問い合わせ
                  <ChevronRight className="ml-2 h-5 w-5" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* 会社概要セクション */}
      <div id="about" className="py-16 bg-gray-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-3xl font-bold text-gray-900 text-center mb-12">会社概要</h2>
          <div className="bg-white rounded-lg shadow overflow-hidden">
            <div className="px-6 py-8">
              <dl className="grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-2">
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500 flex items-center">
                    <Building className="h-5 w-5 mr-2" />
                    会社名
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900">株式会社FREEL</dd>
                </div>
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500 flex items-center">
                    <UserCircle className="h-5 w-5 mr-2" />
                    代表取締役
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900">平野 皓士</dd>
                </div>
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500 flex items-center">
                    <MapPin className="h-5 w-5 mr-2" />
                    所在地
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900">〒302-0110 茨城県守谷市百合ヶ丘1-2024-90</dd>
                </div>
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500 flex items-center">
                    <Calendar className="h-5 w-5 mr-2" />
                    設立
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900">2022年2月2日</dd>
                </div>
                <div className="sm:col-span-2">
                  <dt className="text-sm font-medium text-gray-500">事業内容</dt>
                  <dd className="mt-1 text-sm text-gray-900">
                    <ul className="list-disc pl-5 space-y-2">
                      <li>IoTデバイスの開発・販売</li>
                      <li>通信インフラおよびサーバー構築の支援</li>
                      <li>専用IoTデバイスの設計・製造</li>
                      <li>他社デバイスの技術支援・検証</li>
                    </ul>
                  </dd>
                </div>
              </dl>
            </div>
          </div>
        </div>
      </div>

      {/* 強みセクション */}
      <div id="strengths" className="py-16 bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-3xl font-bold text-gray-900 text-center mb-12">私たちの強み</h2>
          <div className="grid grid-cols-1 gap-8 md:grid-cols-2">
            <div className="bg-gray-50 p-6 rounded-lg">
              <div className="w-12 h-12 rounded-full bg-blue-100 flex items-center justify-center mb-4">
                <Wifi className="h-6 w-6 text-blue-600" />
              </div>
              <h3 className="text-xl font-medium text-gray-900">高度な通信インフラ検証技術</h3>
              <p className="mt-2 text-gray-600">IoTデバイスの通信検証をスムーズに実施し、連続数か月にわたる通信テストも可能です。</p>
            </div>
            <div className="bg-gray-50 p-6 rounded-lg">
              <div className="w-12 h-12 rounded-full bg-blue-100 flex items-center justify-center mb-4">
                <Radio className="h-6 w-6 text-blue-600" />
              </div>
              <h3 className="text-xl font-medium text-gray-900">電気信号解析能力</h3>
              <p className="mt-2 text-gray-600">デバイス内部の電気信号を解析し、内部検証を行うことで高品質な製品を提供します。</p>
            </div>
            <div className="bg-gray-50 p-6 rounded-lg">
              <div className="w-12 h-12 rounded-full bg-blue-100 flex items-center justify-center mb-4">
                <Wrench className="h-6 w-6 text-blue-600" />
              </div>
              <h3 className="text-xl font-medium text-gray-900">問題解決への徹底した取り組み</h3>
              <p className="mt-2 text-gray-600">ログの取得と解析を活用し、問題の根本原因を特定し、再発防止策を提供します。</p>
            </div>
            <div className="bg-gray-50 p-6 rounded-lg">
              <div className="w-12 h-12 rounded-full bg-blue-100 flex items-center justify-center mb-4">
                <Cpu className="h-6 w-6 text-blue-600" />
              </div>
              <h3 className="text-xl font-medium text-gray-900">カスタマイズ対応</h3>
              <p className="mt-2 text-gray-600">顧客特有のニーズに合わせた専用IoTデバイスの設計・製造が可能です。</p>
            </div>
          </div>
        </div>
      </div>

      {/* サービス内容セクション */}
      <div id="services" className="py-16 bg-gray-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-3xl font-bold text-gray-900 text-center mb-12">サービス内容</h2>
          <div className="grid grid-cols-1 gap-8 md:grid-cols-2">
            <div className="bg-white p-6 rounded-lg shadow-sm">
              <div className="w-12 h-12 rounded-full bg-blue-100 flex items-center justify-center mb-4">
                <Cpu className="h-6 w-6 text-blue-600" />
              </div>
              <h3 className="text-xl font-medium text-gray-900">IoTデバイス開発</h3>
              <p className="mt-2 text-gray-600">顧客の要望に合わせたリモート管理可能なデバイスを開発します。</p>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-sm">
              <div className="w-12 h-12 rounded-full bg-blue-100 flex items-center justify-center mb-4">
                <Server className="h-6 w-6 text-blue-600" />
              </div>
              <h3 className="text-xl font-medium text-gray-900">通信インフラ支援</h3>
              <p className="mt-2 text-gray-600">インターネット環境が未整備の顧客に対して、通信インフラやサーバー構築の支援を行います。</p>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-sm">
              <div className="w-12 h-12 rounded-full bg-blue-100 flex items-center justify-center mb-4">
                <Wrench className="h-6 w-6 text-blue-600" />
              </div>
              <h3 className="text-xl font-medium text-gray-900">技術支援・検証サービス</h3>
              <p className="mt-2 text-gray-600">他社デバイスの技術支援や検証を行い、費用対効果の最大化をサポートします。</p>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-sm">
              <div className="w-12 h-12 rounded-full bg-blue-100 flex items-center justify-center mb-4">
                <Factory className="h-6 w-6 text-blue-600" />
              </div>
              <h3 className="text-xl font-medium text-gray-900">遠隔製造設備管理ソリューション</h3>
              <p className="mt-2 text-gray-600">遠隔地の製造設備管理を最適化するためのソリューションを提供します。</p>
            </div>
          </div>
        </div>
      </div>

      {/* お問い合わせセクション */}
      <div id="contact" className="py-16 bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-3xl font-bold text-gray-900 text-center mb-12">お問い合わせ</h2>
          <div className="max-w-3xl mx-auto text-center">
            <p className="text-lg text-gray-600 mb-8">
              製品やサービスに関するご質問・ご相談は、お気軽にお問い合わせください。
            </p>
            <div className="space-y-4">
              <a href="mailto:contact@freel.dev" className="inline-flex items-center text-blue-600 hover:text-blue-800">
                <Mail className="h-5 w-5 mr-2" />
                contact@freel.dev
              </a>
            </div>
          </div>
        </div>
      </div>

      {/* フッター */}
      <footer className="bg-gray-50">
        <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
          <div className="text-center">
            <p className="text-base text-gray-400">&copy; 2024 株式会社FREEL. All rights reserved.</p>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default App;